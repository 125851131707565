<script lang="ts" setup>
const props = defineProps<{
  logoUrl?: string;
  companyName: string;
}>();

const emits = defineEmits<{
  (e: 'accept'): void;
  (e: 'manage'): void;
}>();

const onAccept = (): void => {
  emits('accept');
};

const onManage = (): void => {
  emits('manage');
};
</script>

<template>
  <UCard>
    <template #header>
      <h1 class="text-2xl font-semibold">Cookies Consent</h1>
    </template>

    <div
      v-if="props.logoUrl"
      class="flex justify-center mb-5 bg-company-1 rounded-lg"
    >
      <img :src="props.logoUrl" alt="logo" class="max-h-32">
    </div>

    <p data-test="text">
      To enhance your experience, {{ props.companyName }} uses cookies
      and similar technologies for performance, analytics, and to ensure the
      website works properly. Please check our

      <NuxtLink
        class="font-semibold text-company-1 cursor-pointer focus:outline-none"
        :to="{ name: 'cookiespolicy' }"
        target="_blank"
      >
        Cookies Policy
      </NuxtLink>

      to learn more and adjust your preferences.
    </p>

    <template #footer>
      <div class="flex justify-end gap-4">
        <UButton
          variant="solid"
          class="bg-company-1 flex-grow py-4 font-semibold text-md justify-center"
          data-test="manage-button"
          @click="onManage"
        >
          Manage
        </UButton>

        <UButton
          variant="solid"
          class="bg-company-1 flex-grow py-4 font-semibold text-md justify-center"
          data-test="accept-button"
          @click="onAccept"
        >
          Accept
        </UButton>
      </div>
    </template>
  </UCard>
</template>
